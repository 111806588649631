<template>
  <div class="ApplicationForm">
    <div v-if="android" @click="pickType('android')" class="ApplicationForm__application-type">
      <img class="Project__application-icon"
        src="@/assets/img/android-icon.svg"/>
      <div>Android</div>
    </div>
    <div v-if="ios" @click="pickType('ios')" class="ApplicationForm__application-type">
      <img class="Project__application-icon"
        src="@/assets/img/ios-icon.svg"/>
      <div>iOS</div>
    </div>
    <div v-if="web" @click="pickType('web')" class="ApplicationForm__application-type">
      <img class="Project__application-icon"
        src="@/assets/img/web-icon.svg"/>
      <div>Web</div>
    </div>
    <div v-if="page === 'info'" class="ApplicationForm__form">
      <android-form @confirm="addAndroidApplication" @cancel="cancel" v-if="type ==='android'" />
      <ios-form @confirm="addIosApplication" @cancel="cancel" v-if="type ==='ios'" />
      <web-form @confirm="addWebApplication" @cancel="cancel" v-if="type ==='web'" />
    </div>
    <div v-if="page === 'review'" class="ApplicationForm__review">
      <div class="ApplicationForm__review-text">
        Application is being reviewed by our staff.<br>
        Soon you will be able to use KingsLogin service!
      </div>
      <router-link tag="button" class="ApplicationForm__button" :to="{ name: 'project_details', params: { project_id: projectId() }}">
        Confirm
      </router-link>
    </div>
  </div>
</template>

<script>
import AndroidForm from '@/components/add-appllication/forms/AndroidForm'
import IosForm from '@/components/add-appllication/forms/IosForm'
import WebForm from '@/components/add-appllication/forms/WebForm'

export default {
  name: 'pick_application',
  props: ['page', 'type'],
  methods: {
    pickType (type) {
      if (this.page === 'type') {
        this.$emit('type', type)
      }
    },
    addAndroidApplication (application) {
      this.$store.dispatch('createAndroidApplication', { projectId: this.projectId(), application })
        .then(() => {
          this.goToReview()
        })
    },
    addIosApplication (application) {
      this.$store.dispatch('createIosApplication', { projectId: this.projectId(), application })
        .then(() => {
          this.goToReview()
        })
    },
    addWebApplication (application) {
      this.$store.dispatch('createWebApplication', { projectId: this.projectId(), application })
        .then(() => {
          this.goToReview()
        })
    },
    projectId () {
      return this.$route.params.project_id
    },
    goToReview () {
      this.$emit('review')
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  computed: {
    android () {
      return this.page === 'type'
    },
    ios () {
      return this.page === 'type'
    },
    web () {
      return this.page === 'type'
    }
  },
  components: {
    AndroidForm,
    IosForm,
    WebForm
  }
}
</script>

<style lang="scss" scoped>
@import "./ApplicationForm.scss";
</style>
