<template>
  <div class="AddApplication">
    <div class="AddApplication__title">{{title}}</div>
    <page-indicator class="AddApplication__page-indicator" :pages="pages.length" :page="pages.indexOf(page)" />
    <application-form
      :type="applicationType"
      :page="page"
      @type="setApplicationType"
      @review="reviewPage"
      @cancel="cancel"
    />
  </div>
</template>

<script>
import PageIndicator from '@/components/common/PageIndicator'
import ApplicationForm from '@/components/add-appllication/ApplicationForm'

export default {
  name: 'add_application',
  data () {
    return {
      pages: ['type', 'info', 'review'],
      page: 'type',
      applicationType: null
    }
  },
  methods: {
    setApplicationType (type) {
      this.applicationType = type
      this.page = 'info'
    },
    reviewPage () {
      this.page = 'review'
    },
    cancel () {
      this.$router.push({
        name: 'project_details',
        params: {
          project_id: this.$route.params.project_id
        }
      })
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },
  computed: {
    title () {
      switch (this.page) {
        case 'type':
          return 'Pick application type'
        case 'info': {
          const type = this.capitalizeFirstLetter(this.applicationType)
          return `${type} application info`
        }
        case 'review':
          return 'Application is in review'
        default:
          return ''
      }
    }
  },
  components: {
    ApplicationForm,
    PageIndicator
  }
}
</script>

<style lang="scss" scoped>
@import "./AddApplication.scss";
</style>
