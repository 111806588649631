<template>
  <div class="PageIndicator">
    <div
      v-for="(page, index) in pagesArray"
      :key="index"
      class="PageIndicator__dot"
      :class="{ 'PageIndicator__dot-active': page }"
    />
  </div>
</template>

<script>
export default {
  name: 'page_indicator',
  props: ['pages', 'page'],
  computed: {
    pagesArray () {
      const pages = []
      for (let i = 0; i < this.pages; i++) {
        i === this.page ? pages.push(true) : pages.push(false)
      }
      return pages
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./PageIndicator.scss";
</style>
