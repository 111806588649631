import { render, staticRenderFns } from "./IosForm.vue?vue&type=template&id=0a90f1be&scoped=true&"
import script from "./IosForm.vue?vue&type=script&lang=js&"
export * from "./IosForm.vue?vue&type=script&lang=js&"
import style0 from "./IosForm.vue?vue&type=style&index=0&id=0a90f1be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a90f1be",
  null
  
)

export default component.exports