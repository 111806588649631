<template>
  <div class="Form">
    <span>Name</span>
     <div class="Form__error" v-if="!$v.name.required">
      Field required!
    </div>
    <input class="Form__input" type="text" v-model="name">
    <span>Bundle ID</span>
    <div class="Form__error" v-if="!$v.bundleId.required">
      Field required!
    </div>
    <input class="Form__input" type="text" v-model="bundleId">
    <span>Application icon</span>
    <input class="Form__input-file"
      id="icon"
      ref="fileInput"
      type="file"
      accept="image/*"
      @change="onFileChange">
    <label v-if="fileName" for="icon">{{fileName}}</label>
    <label v-else for="icon">+ Choose icon</label>
    <div class="Form__footer">
      <button class="Form__button-warning" @click="cancel">
        Cancel
      </button>
      <button class="Form__button"
        @click="confirm"
        :class="{ 'Form__button-disabled' : $v.$invalid }"
      >
        Confirm
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import api from '@/api'

export default {
  name: 'ios_form',
  data () {
    return {
      name: null,
      bundleId: null,
      iconUrl: null,
      fileName: null
    }
  },
  methods: {
    confirm () {
      if (!this.$v.$invalid) {
        this.$emit('confirm', {
          name: this.name,
          bundleId: this.bundleId,
          iconUrl: this.iconUrl
        })
      }
    },
    cancel () {
      this.$emit('cancel')
    },
    onFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return false
      const file = files[0]
      api.uploadFile(file).then(response => {
        this.fileName = file.name
        this.iconUrl = response.body.public_url
      })
    }
  },
  validations: {
    name: {
      required
    },
    bundleId: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./Form.scss";
</style>
